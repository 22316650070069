// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import authRoute from 'hoc/routeAuth';
import Login from 'views/pages/login';
import Layout from 'views/pages/layout';
import Dashboard from 'views/pages/dashboard';
import CustMedication from 'views/pages/customers/medications';
import QrReport from 'views/pages/customers/qr-report';
import LapReport from 'views/pages/customers/lab';
import MonthlyCommission from 'views/pages/accounting/commission';
import DailyReceipt from 'views/pages/accounting/daily-receipt';
import NurseLayout  from 'views/pages/nurse/layout';
import NurseDashboard  from 'views/pages/nurse/dashboard';
import NursePatientOrder  from 'views/pages/nurse/patient/order';
import NursePatientOrderHistory  from 'views/pages/nurse/patient/orderHistory';
import NurseSettingAddInvent  from 'views/pages/nurse/settings/addInventories';
import PageNotFound from 'components/PageNotFound';

function App() {
	const AuthenticatedLogin = authRoute(Login);
	const AuthenticatedDashboard = authRoute(Dashboard);
	const AuthenticatedCustMed = authRoute (CustMedication);
	const AuthenticatedLapReport= authRoute (LapReport);
	const AuthenticatedActCommission= authRoute (MonthlyCommission);
	const AuthenticatedActDailyReceipt= authRoute (DailyReceipt);
	const AuthenticatedNurseDashboard = authRoute (NurseDashboard);
	const AuthenticatedNursePatientOrder = authRoute (NursePatientOrder);
	const AuthenticatedNursePatientOrderHistory = authRoute (NursePatientOrderHistory);
	const AuthenticatedNurseAddInvent = authRoute (NurseSettingAddInvent);

	return (
		<div className="app">
			<Router>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route path="/" element={<AuthenticatedDashboard />} />
						<Route path="/dashboard" element={<AuthenticatedDashboard />} />
						<Route path="/customer/medication-qr" element={<AuthenticatedCustMed />} />
						<Route path="/customer/lab-report" element={<AuthenticatedLapReport />} />
						<Route path="/accounting/monthly-commission" element={<AuthenticatedActCommission />} />
						<Route path="/accounting/daily-receipt" element={<AuthenticatedActDailyReceipt />} />
					</Route>
					<Route path="/nurse" element={<NurseLayout />}>
						<Route path="/nurse" element={<AuthenticatedNurseDashboard />} />
						<Route path="/nurse/patient-order" element={<AuthenticatedNursePatientOrder />} />
						<Route path="/nurse/patient-order-history" element={<AuthenticatedNursePatientOrderHistory />} />
						<Route path="/nurse/settings-add-inventories" element={<AuthenticatedNurseAddInvent />} />
					</Route>
					<Route path="/login" element={<AuthenticatedLogin />} />
					<Route path="/report/:reportId" element={<QrReport />} />
					
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;