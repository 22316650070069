import React from 'react';
import { Navigate } from 'react-router-dom';

const authRoute = (Component) => () => {
	const token = sessionStorage.getItem('token');

	const isLoginRoute = window.location.pathname === '/login';

	if (token && isLoginRoute) {
		// Redirect to /dashboard if no token and not on the /login route
		return <Navigate to="/dashboard" />;
	  }

	if (!token && !isLoginRoute) {
		// Redirect to the login page if the token is not present
		return <Navigate to="/login" />;
	}

	// Invoke the Component as a function if the token is present
	return <Component/>;
};

export default authRoute;