import axios from 'hoc/middleware';

const LoginController = {
    async login(username,password){
        try {
            const response = await axios.post(`/api/user/login`, {
                username,
                password
            });
            
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('user', JSON.stringify(response.data.user));
            
        } catch (error) {
            // Handle errors, log them, or return an error object
            throw error;
        }
    },

    async logout(){
        try {
            sessionStorage.clear();
            
        } catch (error) {
            // Handle errors, log them, or return an error object
            throw error;
        }
    }
};

export default LoginController;