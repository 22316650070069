// Modal.jsx
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalTemp = ({ show, onClose, toProcess, children, title = "Info",confirm=true }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {confirm && (
          <Button variant="secondary" onClick={toProcess}>
            Confirm
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTemp;
