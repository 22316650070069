import React, { useEffect, useState, useRef } from "react";
import CustomerController from "controllers/CustomerController";

const History = () => {
    const fetchedRef = useRef(false);
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Added isLoading state

    const fetchReportData = async () => {
        try {
            setIsLoading(true);
            const response = await CustomerController.getLabReports();
            if (Array.isArray(response.data)) {
                setReports(response.data);
            } else {
                console.error('Expected an array for reports, but got:', response);
                setReports([]); // Set reports to an empty array if response is not an array
            }
        } catch (error) {
            console.error('Error fetching report data:', error);
        } finally {
            setIsLoading(false); // Set isLoading to false after fetching data
        }
    };

    const downloadReport = (url) => {
        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        if (!fetchedRef.current) {
            fetchReportData();
            fetchedRef.current = true; // Mark as fetched
        }
    }, []); // Add empty dependency array to ensure it runs once

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="d-flex flex-column justify-content-center align-items-center"> {/* Added flex-column here */}
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Generated Lab Reports</h6>
                        </div>
                        <div className="card-body">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">HN</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map(report => (
                                        <tr key={report.id}>
                                            <td>{report.cust_name}</td>
                                            <td>{report.cust_hn}</td>
                                            <td>{report.report_type}</td>
                                            <td>{new Date(report.created).toLocaleDateString()}</td>
                                            <td>
                                                <button type="button" className="btn btn-primary" style={{ fontSize: '12px', padding: '2px 5px' }} onClick={() => downloadReport(report.file_url)}>
                                                    <i className="bi bi-download"> </i>
                                                    Download
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default History;
