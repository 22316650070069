import React from "react";
import GenerateMedsQrComp from "components/customers/medications/GenerateMedsQrComp";
import MedsQrHistoryComp from "components/customers/medications/MedsQrHistoryComp";

const Medications = () => {
    return (
        <>
          <GenerateMedsQrComp/>
          <MedsQrHistoryComp/>
        </>
    );
};

export default Medications;
