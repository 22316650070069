import React, { useEffect, useState,useRef } from "react";
import CustomerController from "controllers/CustomerController";

const GenerateMedsQrComp = () => {
    const fetchedRef = useRef(false); // Fetch report data when the component mounts
    const [qrList, setQrList] = useState([]);

    const fetchCustomerQr = async () => {
        try {
            const getAllQrResp = await CustomerController.getAllQr();
            setQrList(getAllQrResp.custQr);

        } catch (error) {
            // Handle error if needed
        }
    };

    useEffect(() => {
        if (!fetchedRef.current) {
            fetchCustomerQr();
            fetchedRef.current = true; // Mark as fetched
        }
    }, []);
   
    const downloadQR = (qrURL,filename) => {
        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = qrURL;
        downloadLink.download = `${filename}.png`; // You can set the filename here
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Generated QR History</h6>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Language</th>
                                        <th scope="col">URL</th>
                                        <th scope="col">QR</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {qrList.map(qr => (
                                        <tr key={qr.id}>
                                            <td>{qr.cust_name}</td>    
                                            <td>{qr.language}</td>
                                            <td>{process.env.REACT_APP_BASE_URL}/report/{qr.id}</td>
                                            <td>
                                                <img 
                                                    src={qr.qr} 
                                                    alt="QR Code"
                                                    style={{
                                                        width: '100px', 
                                                        height: '100px'
                                                    }}
                                                />
                                                </td>
                                            <td>{qr.created}</td>
                                            <td>
                                                <button type="button" className="btn btn-primary float-right" style={{fontSize:'10px', padding:'2px 5px'}}  onClick={()=> downloadQR(qr.qr,qr.cust_name+'_'+qr.created)}>
                                                    <i className="bi bi-download"> </i>
                                                    Download QR
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GenerateMedsQrComp;