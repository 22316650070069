import React from 'react';

const Dashboard = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-center mb-4">
                    <h1 className="h3 mb-0 text-gray-800">
                        Dr. Orawan Medical Center Dashboard
                    </h1>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
