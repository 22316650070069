import 'views/css/login.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginController from "controllers/LoginController";

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');


    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        setUsernameError('');
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!username) {
            setUsernameError('Username is required');
            return;
        }

        if (!password) {
            setPasswordError('Password is required');
            return;
        }

        try {
            await LoginController.login(username, password);
            setPasswordError('');
            var userSession = sessionStorage.getItem('user');
            userSession = JSON.parse(userSession);
            
            if(userSession.roles.toUpperCase() === 'NURSE'){
                navigate('/nurse');
            }else{
                navigate('/dashboard');
            }

        } catch (error) {
            setPasswordError('Incorrect username or password.');
        }
    };

    return(
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <div className="fadeIn first">
                    <img src="./image/logo/logo_vertical.png" id="icon" alt="User Icon" />
                </div>

                <form onSubmit={handleSubmit}>
                    <input type="text" id="lg-user" className={`fadeIn second ${usernameError ? 'error' : ''}`} name='username' value={username} onChange={handleUsernameChange} placeholder="username" />
                    {usernameError && <div className="error-message">{usernameError}</div>}
                    <input type="password" id="lg-password" className={`fadeIn third ${passwordError ? 'error' : ''}`} name='password' value={password} onChange={handlePasswordChange} placeholder="password" />
                    {passwordError && <div className="error-message">{passwordError}</div>}
                    <input type="submit" className="fadeIn fourth" value="Log In" />
                </form>

                <div id="formFooter">
                    {/* <a className="underlineHover" href="#">Forgot Password?</a> */}
                </div>
            </div>
        </div>
    );
};
    
export default Login;
    