import axios from 'hoc/middleware';


const AccountingController = {
    commission: async (data) => {
		try {	
            const response = await axios.post(`/api/commission/generate-report`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const medCache = 'get-commission';

			const existingCache = sessionStorage.getItem(medCache);

			if (existingCache && response.data.status) {
				sessionStorage.removeItem(medCache);
			}

			return response.data;
		} catch (error) {
			throw error;
		}
	},

    getCommission: async ()=>{
        try{
            const medCache = 'get-commission';

			const existingCache = sessionStorage.getItem(medCache);
			if (existingCache) {
				return JSON.parse(existingCache); // Parse and return the cached data
			}

            const response = await axios.get(`/api/commission/get-commission`);

            sessionStorage.setItem(medCache, JSON.stringify(response.data.commissionObj));
            
			return response.data.commissionObj;
        }catch (error) {
			throw error;
		}
    },

    dailyReport: async (data) => {
		try {	
            var response = await axios.post(`/api/accounting/generate-daily-data`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            response = response.data;
            console.log(response)

            if(response.status){
                response = await axios.post(`/api/accounting/generate-daily-report`, response.response);

                response = response.data;

                const medCache = 'get-daily-report';
                const existingCache = sessionStorage.getItem(medCache);
                if (existingCache && response.status) {
                    sessionStorage.removeItem(medCache);
                }
            }
			return response;
		} catch (error) {
            console.log(`Error: ${error}`);
			throw error;
		}
	},

    getDailyReport: async ()=>{
        try{
            const medCache = 'get-daily-report';

			const existingCache = sessionStorage.getItem(medCache);
			if (existingCache) {
				return JSON.parse(existingCache); // Parse and return the cached data
			}

            const response = await axios.get(`/api/accounting/get-daily`);
            sessionStorage.setItem(medCache, JSON.stringify(response.data.dailyReportObj));

			return response.data.dailyReportObj;
        }catch (error) {
			throw error;
		}
    },
};

export default AccountingController;