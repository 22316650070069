import React, { useState } from "react";
import CustomerController from "controllers/CustomerController";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const Upload = () => {
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState("");
    const [formError, setFormError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        hn: '',
        sex: ''
    });

    const handleFileChange = (event) => {
        setError("");
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
        const fileNamesList = selectedFiles.map(file => file.name).join("\n");
        setFileNames(fileNamesList);
    };

    const handleGenerateReport = () => {
        if (files.length === 0) {
            setError("Please select a file.");
            return;
        }

        const validPrefixes = ["SampleInfo", "UserReport", "userreport", "sampleinfo"];
        const allFilesValid = files.every(file => 
            validPrefixes.some(prefix => file.name.startsWith(prefix))
        );

        if (!allFilesValid) {
            setError("Files must start with 'SampleInfo', 'UserReport', 'userreport', or 'sampleinfo'. Example: SampleInfo_29042024_123213.csv or UserReport_128003769_2024-05-25_18_07_40_0_2.csv");
            return;
        }

        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormError("");
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        if (!formData.name || !formData.sex || !formData.hn) {
            setFormError("All information is required");
            return;
        }

        try {
            setIsLoading(true);
            setError("");
            setFormError("");
            const data = await CustomerController.readLabReport(files, formData);
            console.log('Merged Data:', data);
            setShowModal(false);
            setIsLoading(false);
            window.location.reload();

        } catch (error) {
            setIsLoading(false);
            setFormError(error.message);
            setError(error.message);
            console.error("Error generating report:", error);
        }
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="d-flex flex-column justify-content-center align-items-center"> {/* Added flex-column here */}
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Upload Lab Reports</h6>
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-3'></div>
                                <div className='col-6'>
                                    <div className="mb-3">
                                        <input className="form-control" type="file" id="formFileMultiple" multiple onChange={handleFileChange} />
                                    </div>
                                    <div className="mb-3">
                                        <textarea className="form-control" rows="3" readOnly value={fileNames} />
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-primary float-right" onClick={handleGenerateReport}>
                                            Generate Report &nbsp;
                                            <i className="bi bi-upload"></i>
                                        </button>
                                    </div>
                                    <div>
                                        {error && <div className="text-danger">{error}</div>}
                                    </div>
                                </div>
                                <div className='col-3'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Customer Information</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="formFullName">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formHN">
                                    <Form.Label>HN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="hn"
                                        value={formData.hn}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="formSex">
                                    <Form.Label>Sex*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="sex"
                                        value={formData.sex}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {formError && <div className="text-danger">{formError}</div>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Upload;
