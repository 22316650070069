import axios from 'axios';

// Function to create an Axios instance
const createAxiosInstance = () => {
  const apiURL = process.env.REACT_APP_API_URL;

  // Create an Axios instance with a baseURL and default JSON content type
  const instance = axios.create({
    baseURL: apiURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Use a request interceptor to dynamically set the authorization header
  instance.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('token');

    // If a token is available, use it for the authorization header
    if (token) {
      config.headers['authorization'] = token;
    } else {
      // Otherwise, use the API key if no token is present
      config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    }

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    response => response, // If the response is successful, return it as is
    error => {
      // If the response status code is 401, redirect to the login page
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance();
