// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link:hover {
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
}

.modal-header, .modal-footer {
    padding: 10px;
}
  
.modal-body {
    margin: 10px 0;
}
  
.button {
    cursor: pointer;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}

.small-font {
    font-size: 0.5rem; /* Adjust the font size as needed */
}

.card-title{
    color: #99843b;
}

.sidebar-divider {
    border-top: 1px solid white !important; /* Change 'red' to your desired color */
  }`, "",{"version":3,"sources":["webpack://./src/views/css/custom.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,gBAAgB;AAChB;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iBAAiB,EAAE,mCAAmC;AAC1D;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,sCAAsC,EAAE,uCAAuC;EACjF","sourcesContent":[".nav-link:hover {\n    cursor: pointer;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* For Firefox */\ninput[type='number'] {\n    -moz-appearance: textfield;\n}\n\n.modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.modal-content {\n    background-color: white;\n    padding: 20px;\n    border-radius: 5px;\n    width: 50%;\n}\n\n.modal-header, .modal-footer {\n    padding: 10px;\n}\n  \n.modal-body {\n    margin: 10px 0;\n}\n  \n.button {\n    cursor: pointer;\n    padding: 10px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n}\n\n.small-font {\n    font-size: 0.5rem; /* Adjust the font size as needed */\n}\n\n.card-title{\n    color: #99843b;\n}\n\n.sidebar-divider {\n    border-top: 1px solid white !important; /* Change 'red' to your desired color */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
