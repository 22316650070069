import React, { useState, useEffect,useRef } from 'react';
import NurseController from "controllers/NurseController";
import ConfirmModal from 'components/modal/confirm';
import ErrorModal from 'components/modal/error';

const SettingAddInventory = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);
    
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [error, setError] = useState("");

    const [isLoading, setIsLoading] = useState(false); // Loading state

    const [updatedQuantities, setUpdatedQuantities] = useState({});

    const handleQuantityChange = (item, value) => {
        setUpdatedQuantities((prevQuantities) => ({
          ...prevQuantities,
          [item.id]: { name: item.name, quantity: Number(value) }
        }));
    };

    const [openItemId, setOpenItemId] = useState(null);
    
    const toggleItem = (itemId) => {
        if (openItemId === itemId) {
            setOpenItemId(null);
        } else {
            setOpenItemId(itemId);
        }
    };

    const isMobileView = () => {
        return window.innerWidth < 768; // Adjust the breakpoint as needed
    };

    // Conditionally apply the class to the table
    const nurseordertable = isMobileView() ? 'table small-font' : 'table';

    const fetchedRef = useRef(false); // Fetch report data when the component mounts
    const [inventories, setInventories] = useState([]);

    const fetchData = async () => {
        setIsLoading(true); // Start loading

        try {
            const response = await NurseController.getAllInventory();
            setInventories(response);

        } catch (error) {
            console.error('Error fetching report data:', error);
        }finally{
            setIsLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        if (!fetchedRef.current) {
            fetchData();
            fetchedRef.current = true; // Mark as fetched
        }
    });

    const UpdateNowProcess = async () =>{
        const tableRows = Object.values(updatedQuantities)
        .filter(({ quantity }) => !isNaN(quantity) && quantity !== 0)
        .map(({ name, quantity }) => (
            <tr>
                <td>{name}</td>
                <td>{quantity}</td>
            </tr>
        ));
        
            
        if(tableRows.length === 0){
            setIsErrorModalOpen(true);
            setError("There is no updated quantity");   

        } else {
            setIsErrorModalOpen(false);
            setError('');

            setModalContent(tableRows);
            setIsModalOpen(true);
        }
    }

    const ConfirmProcess = async () =>{
        setIsLoading(true); // Start loading

        const payload = {
            add:{},
            update: updatedQuantities,
        };

        NurseController.addInventory(payload).then(() => {
          // Handle success, maybe close the modal and reset state
          window.location.reload();
          setIsModalOpen(false);
          
        }).catch((error) => {
            setIsLoading(false); // Start loading
            setIsErrorModalOpen(true);
            setError(`Backend Process Error: ${error}`);
        });
    }

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="d-flex flex-column justify-content-center align-items-center"> {/* Added flex-column here */}
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
        <div className="container">
            <div className="row">
                <h5>+ Adding Inventories</h5>
            </div>
            <div className="row pt-3">
                <div id="accordion">
                    {Object.entries(inventories).map(([category, inventories], index) => (
                        <div className="card">
                            <div className="card-header" id={`heading${index + 1}`}>
                                <h5 className="mb-0">
                                    <button
                                    className={`btn btn-link ${openItemId === index + 1 ? '' : 'collapsed'}`}
                                    onClick={() => toggleItem(index + 1)}
                                    aria-expanded={openItemId === index + 1}
                                    aria-controls={`collapse${index + 1}`}
                                    >
                                    {category}
                                    </button>
                                </h5>
                            </div>

                            <div id={`collapse${index + 1}`} className={`collapse ${openItemId === index + 1 ? 'show' : ''}`} aria-labelledby={`heading${index + 1}`}>
                                <div className="card-body">
                                    <table className={nurseordertable}>
                                        <thead>
                                            <tr>
                                                <th scope="col">Items</th>
                                                <th scope="col">Inventory Qty</th>
                                                <th scope="col">+ Add Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inventories.map((itemDetail, itemIndex) => (
                                                <tr>
                                                    <td>{itemDetail.name}</td>    
                                                    <td>{itemDetail.qty}</td>
                                                    <td>
                                                        <input 
                                                        type="number" 
                                                        name={itemDetail.name}
                                                        className="form-control" 
                                                        onChange={(e) => handleQuantityChange(itemDetail, e.target.value)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="row pt-3">
                <button type="button" className="btn btn-primary" onClick={UpdateNowProcess}>
                    Update Now
                </button>
            </div>
        </div>

        <ConfirmModal show={isModalOpen} onClose={() => setIsModalOpen(false)} toProcess={ConfirmProcess} title="Order Summary">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Items</th>
                        <th scope="col">Update Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {modalContent}
                </tbody> 
            </table>
        </ConfirmModal>


        <ErrorModal show={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
            <div className="text-danger">{error}</div>
        </ErrorModal>
        </>
    )
}

export default SettingAddInventory;