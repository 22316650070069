import React, { useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';
import CustomerController from "controllers/CustomerController";

const ReportDisplay = () => {
    const fetchedRef = useRef(false); // Fetch report data when the component mounts
    const { reportId } = useParams();
    const [items, setItems] = useState([]);
    const [lang, setLang] = useState('');
    const [custName, setCustName] = useState('');
    const [isLoading, setIsLoading] = useState(true); // Loading state

    const fetchReportData = async () => {
        setIsLoading(true); // Start loading

        try {
            const response = await CustomerController.getInfoHtml(reportId);
            setItems(response.data.items);
            setLang(response.language);
            setCustName(response.cust_name);
        } catch (error) {
            console.error('Error fetching report data:', error);
        }finally{
            setIsLoading(false); // Stop loading
        }
    };
    
    useEffect(() => {
        if (!fetchedRef.current) {
            fetchReportData();
            fetchedRef.current = true; // Mark as fetched
        }
    });

    if (isLoading) {
        // This is the Bootstrap loading screen
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
    
    return (
        <>
            <div className="container px-5 my-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-11 col-xl-9 col-xxl-8">
                        <section>
                            <div className="card shadow border-0 rounded-4 mb-5">
                                <div className="card-body p-5" style={{ backgroundColor: '#F8F7F3'}}>
                                    <div className="row align-items-center gx-5">
                                        <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                            <div className="bg-light p-4 rounded-4" >
                                                <img className="card-img-top" src='/image/logo/logo_vertical.png' alt="Logo" style={{ width: '100%', height: 'auto'}}/> 
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div>
                                                {lang === 'EN' ?
                                                    ( 
                                                        <>
                                                            <p>Thank you <b>{custName}</b> for visting Dr. Orawan Medical Center.</p>
                                                            <p>This QR will provide you the information about your medication.</p>
                                                            <p>If you would like more information do not hesitate to contact us at +66 6-1656-2395 or on LINE @drorawan</p>
                                                        </>
                                                    )
                                                    :
                                                    ( 
                                                        <>
                                                            <p>ขอขอบคุณคุณ <b>{custName}</b> ที่เข้ามาใช้บริการที่ ดร.อรวรรณ คลินิก</p>
                                                            <p>รายการด้านล่างคือรายละเอียดของอาหารเสริมที่คุณหมอจัดให้คุณโดยเฉพาะค่ะ</p>
                                                            <p>หากคุณต้องการข้อมูลเพิ่มเติม โปรดติดต่อเราที่ +66 6-1656-2395 หรือไลน์ @drorawan</p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </section>
                    </div>
                </div>
            </div>

            <div className="container px-5 my-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-11 col-xl-9 col-xxl-8">
                        <section>
                            {items.map(item => (
                                <div key={item.id} className="card shadow border-0 rounded-4 mb-5">
                                    <div className="card-body p-5">
                                        <div className="row align-items-center gx-5">
                                            <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                                <div className="bg-light p-4 rounded-4">
                                                    <img className="card-img-top" src={item.img_path} alt="Medication" style={{ width: '100%', height: 'auto'}}/> 
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div>
                                                    <p>
                                                        {lang === 'TH' ? 
                                                            (item.th_name ? item.th_name : item.eng_name) :
                                                            item.eng_name
                                                        }
                                                    </p>
                                                    
                                                    <span dangerouslySetInnerHTML={{ __html: lang === 'EN' ? item.eng_description_01 : item.th_description_01 }}></span>
                                                      
                                                    <span dangerouslySetInnerHTML={{ __html: lang === 'EN' ? item.eng_description_02 : item.th_description_02 }}></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            ))}
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportDisplay;
