import React from "react";
import { Outlet,useNavigate } from "react-router-dom";

import LoginController from "controllers/LoginController";

const Layout = () => {
	const navigate = useNavigate();
	
	const handleLogout = async () => {
        const response = await LoginController.logout();
		navigate('/login');
    };

    return (
		<div id='wrapper'>
			<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
				<a className="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
					<div className="sidebar-brand-icon rotate-n-15">
						<i className="fas fa-laugh-wink"></i>
					</div>
					<div className="sidebar-brand-text mx-3">
						<img src="/image/logo/logo_vertical.png" id="icon" alt="Logo" />
					</div>
				</a>

				<hr className="sidebar-divider" />

				<div className="sidebar-heading">
					Generate Customer's
				</div>
				<li className="nav-item">
					<a className="nav-link collapsed" href="/customer/medication-qr">
						<i className="fas fa-fw fa-cog"></i>
						<span>Medication Description QR</span>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link collapsed" href="/customer/lab-report">
						<i className="fas fa-fw fa-cog"></i>
						<span>Lab Report</span>
					</a>
				</li>
				
				<hr className="sidebar-divider" />
				
				<div className="sidebar-heading">
					Accounting Reports
				</div>
				<li className="nav-item">
					<a className="nav-link collapsed" href="/accounting/daily-receipt">
						<i className="fas fa-fw fa-cog"></i>
						<span>Daily Receipt</span>
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link collapsed" href="/accounting/monthly-commission">
						<i className="fas fa-fw fa-cog"></i>
						<span>Monthly Commission</span>
					</a>
				</li>

				<hr className="sidebar-divider" />

				<div className="sidebar-heading">
					Dashboard
				</div>
				<li className="nav-item">
					<a className="nav-link collapsed" href="/nurse">
						<i className="fas fa-fw fa-cog"></i>
						<span>Nurse Portal</span>
					</a>
				</li>
			</ul>

			<div id="content-wrapper" className="d-flex flex-column">
				<div id="content">
					<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
						<div className="navbar-collapse collapse">
							<a className="nav-link collapsed ml-auto" onClick={handleLogout}>
								<span className='logout-txt pr-1'><b>Logout</b></span>
								<i className="bi bi-box-arrow-right"></i>
							</a>
						</div>
					</nav>
					<div className="container-fluid">
						<Outlet/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Layout;
