import axios from 'hoc/middleware';
import * as XLSX from 'xlsx';

const CustomerController = {
	getAllMedications: async () => {
		try {
			const medCache = 'medications';

			const existingCache = sessionStorage.getItem(medCache);
			if (existingCache) {
				return JSON.parse(existingCache); // Parse and return the cached data
			}

			const response = await axios.get(`/api/medication/get-all`);

			sessionStorage.setItem(medCache, JSON.stringify(response.data.data));

			return response.data.data;
			
		} catch (error) {
			throw error;
		}
	},

	generateMedFile: async (data) => {
		try {	
			const response = await axios.post(`/api/medication-qr/create`, data);

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	getInfoHtml: async (reportId) => {
		try{
			const response = await axios.get(`/api/medication-qr/get/${reportId}`);
			return response.data.medQrObj;
		}catch(error){
			throw error;
		}
	},

	getAllQr: async () =>{
		try{
			const response = await axios.get(`/api/medication-qr/get-all`);
			return response.data
		}catch(error){
			throw error;
		}
	},

	readLabReport: async (files,customer_info) =>{
		const readFile = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryStr = e.target.result;
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
					let jsonData;

                    if (file.name.startsWith("UserReport")) {
                        // Read only rows 17 to 42 (Excel rows are 1-based, hence the range "16:41")
                        const range = { s: { r: 16, c: 0 }, e: { r: 41, c: worksheet['!ref'].split(':')[1].charCodeAt(0) - 65 } };
                        jsonData = XLSX.utils.sheet_to_json(worksheet, { range });

						// Transform the data to keep only "Assay" and "Result" as key-value pairs
						const transformedData = jsonData.reduce((acc, item) => {
							if (item.Assay && item.Result) {
								acc[item.Assay] = item.Result;
							}
							return acc;
						}, {});

						resolve(transformedData);

                    } else if (file.name.startsWith("SampleInfo")) {
                        // Read columns Y to BC
                        const startCol = XLSX.utils.decode_col('Y'); // Column Y
                        const endCol = XLSX.utils.decode_col('BC'); // Column BC
                        const range = { s: { r: 0, c: startCol }, e: { r: worksheet['!ref'].split(':')[1].match(/\d+/)[0] - 1, c: endCol } };
                        jsonData = XLSX.utils.sheet_to_json(worksheet, { range }); 

						// Transform the data to keep only certain key-value pairs
						const transformedData = jsonData.reduce((acc, item) => {
							Object.keys(item).forEach(key => {
								acc[key] = item[key];
							});
							return acc;
						}, {});

						resolve(transformedData);
					} else {
						reject(new Error('Unsupported file name. Only support file name that start with "UserReport" or "SampleInfo".	'));
                        return;
                    }

                    resolve(jsonData);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsBinaryString(file);
            });
        };

        try {
            const fileData = await Promise.all(files.map(file => readFile(file)));
			// Merge all objects into a single object
			const data = fileData.reduce((acc, obj) => ({ ...acc, ...obj }), {});

			const request = {customer_info, data};

			console.log(request);

			const response = await axios.post(`/api/lab/generate-report`, request);

			if (response.status == 200){
				sessionStorage.removeItem('get-lab-reports');
				return response.data;
			}else{
				throw new Error(`Something went wrong. ${response.data}`);
			}


        } catch (error) {
            throw new Error(`Error reading files: ${error.message}`);
        }
	},
	
	getLabReports: async ()=>{
        try{
            const cache = 'get-lab-reports';

			const existingCache = sessionStorage.getItem(cache);
			if (existingCache) {
				return JSON.parse(existingCache); // Parse and return the cached data
			}

            const response = await axios.get(`/api/lab/get-reports`);
			console.log(response);

			if(response.status == 200){
				sessionStorage.setItem(cache, JSON.stringify(response));
				return response;
			}else{
				throw new Error(`Something went wrong. ${response}`);
			}

            
        }catch (error) {
			throw error;
		}
    },
};

export default CustomerController;