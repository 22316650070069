import { Outlet,useNavigate } from "react-router-dom";
import LoginController from "controllers/LoginController";

const Layout = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        const response = await LoginController.logout();
		navigate('/login');
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            {/* Header with Navigation */}
            <header>
                <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: '#F8F7F3' }}>
                    <div className="container">
                        <a href="/nurse">
                            <div className="me-auto"> 
                                <img src="/image/logo/logo_horizontal.png" alt="Nurse Portal Logo" height="100" />
                            </div>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Dashboard</a>
                                    
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href="/nurse">Nurse</a>
                                        <a className="dropdown-item" href="/">Admin</a>
                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Patients</a>
                                    
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href="/nurse/patient-order">Order (ออเดอร์)</a>
                                        <a className="dropdown-item" href="/nurse/patient-order-history">Order History (ประวัติการสั่งซื้อ)</a>
                                    </div>
                                </li>


                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Settings</a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href="/nurse/settings-add-inventories">+ Add Inventories (เพิ่มสต็อก)</a>
                                    </div>
                                </li>
                                
                                <li className="nav-item">
                                    <a className="nav-link collapsed ml-auto" onClick={handleLogout}>
                                        Logout <i className="bi bi-box-arrow-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            {/* Main Content */}
            <main className="flex-grow-1">
                <div className="container py-4">
                    <Outlet/>
                </div>
            </main>

            {/* Footer */}
            {/* <footer className="bg-light text-center text-lg-start mt-auto">
                <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                    © 2024 Nurse Portal
                </div>
            </footer> */}
        </div>
    );
};

export default Layout;
