import React, { useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';
import UploadReport from 'components/customers/lab/upload'
import HistoryReport from 'components/customers/lab/history'


const Lab = () => {
    return (
        <>
            <UploadReport/>
            <HistoryReport/>
        </>
    );
};

export default Lab;