import axios from 'hoc/middleware';

const NurseController = {
    getAllInventory: async () => {
		try {	
			const existingCache = sessionStorage.getItem('inventory');

			if (existingCache) {
				return JSON.parse(existingCache); // Parse and return the cached data
			}

            const response = await axios.get(`/api/inventory/get-all`);

            sessionStorage.setItem('inventory', JSON.stringify(response.data));

			return response.data;
		} catch (error) {
			throw error;
		}
	},

    processOrder: async (data) => {
		try {	
            const response = await axios.post(`/api/inventory/order`,data);
			sessionStorage.removeItem('inventory');
			sessionStorage.removeItem('low-inventory');
            
		} catch (error) {
			throw error;
		}
	},

	addInventory: async (data) => {
		try {	
            const response = await axios.post(`/api/inventory/add`,data);
			sessionStorage.removeItem('inventory');
			sessionStorage.removeItem('low-inventory');
            
		} catch (error) {
			throw error;
		}
	},

	getLowStock: async () => {
		try {	
			const existingCache = sessionStorage.getItem('low-inventory');

			if (existingCache) {
				return JSON.parse(existingCache); // Parse and return the cached data
			}

            const response = await axios.get(`/api/inventory/get-low-stock`);
			console.log(response.data);
            sessionStorage.setItem('low-inventory', JSON.stringify(response.data));

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	getOrderHistory: async () =>{
		try {	
			
            const response = await axios.get(`/api/inventory/get-orders`);
			console.log(response);

			return response;
		} catch (error) {
			throw error;
		}
	}
}

export default NurseController;