import React, { useEffect, useState, useRef } from "react";
import NurseController from "controllers/NurseController";
import ConfirmModal from 'components/modal/confirm';

const PatientOrderHistory = () => {
    const fetchedRef = useRef(false);
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);

    const fetchReportData = async () => {
        try {
            setIsLoading(true);
            const response = await NurseController.getOrderHistory();
            if (Array.isArray(response.data)) {
                setReports(response.data);
            } else {
                console.error('Expected an array for reports, but got:', response);
                setReports([]);
            }
        } catch (error) {
            console.error('Error fetching report data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const viewOrder = (data) => {
        if (data && typeof data === 'object') {
            const entries = Object.entries(data).map(([key, value]) => ({
                id: key,
                ...value
            }));
            setModalContent(entries);
        } else {
            setModalContent([]);
            console.error('Expected an object for order data, but got:', data);
        }
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (!fetchedRef.current) {
            fetchReportData();
            fetchedRef.current = true;
        }
    }, []);

    return (
        <>
            <div>
                <div className="container mt-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 text-center mb-4">
                                    <h5>Patient Order History</h5>
                                </div>
                            </div>
                            {isLoading ? (
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-12 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-md-12 mb-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Customer HN</th>
                                                    <th scope="col">Customer Name</th>
                                                    <th scope="col">Order Date</th>
                                                    <th scope="col">Orders</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reports.map(report => (
                                                    <tr key={report.id}>
                                                        <td>{report.cust_hn}</td>
                                                        <td>{report.cust_name}</td>
                                                        <td>{new Date(report.created).toLocaleDateString()}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" style={{ fontSize: '12px', padding: '2px 5px' }} onClick={() => viewOrder(report.data)}>
                                                                <i className="bi bi-view-list"> </i>
                                                                View Order
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmModal show={isModalOpen} onClose={() => setIsModalOpen(false)} title="Order History" confirm={false}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Items</th>
                            <th scope="col">Ordered Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {modalContent.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ConfirmModal>
        </>
    );
}

export default PatientOrderHistory;