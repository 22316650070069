import React, { useState, useEffect,useRef } from 'react';
import NurseController from "controllers/NurseController";

const InventoryView = () => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    
    useEffect(() => {
        const updateMobileView = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener('resize', updateMobileView);
        return () => {
            window.removeEventListener('resize', updateMobileView);
        };
    }, []);

    const nurseordertable = isMobileView ? 'table text-center small-font' : 'table text-center';

    const [isOpen, setIsOpen] = useState(false); // State to manage the accordion
    const toggleAccordion = () => setIsOpen(!isOpen); // Toggle function for the accordion


    const fetchedRef = useRef(false); // Fetch report data when the component mounts
    const [isLoading, setIsLoading] = useState(false); // Loading state
    
    const [lowItems, setLowItems] = useState([]);
    const fetchData = async () => {
        setIsLoading(true); // Start loading

        try {
            const response = await NurseController.getLowStock();
            setLowItems(response);
        } catch (error) {
            console.error('Error fetching report data:', error);
        }finally{
            setIsLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        if (!fetchedRef.current) {
            fetchData();
            fetchedRef.current = true; // Mark as fetched
        }
    });

    return (
        <>
        <div id="accordion">
            <div className="card">
                <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                        <button
                            className="btn btn-link"
                            onClick={toggleAccordion}
                            aria-expanded={isOpen}
                            aria-controls="collapseOne"
                        >
                            <b className='card-title'>Low Stock Items (สินค้าคงคลังต่ำ)</b>
                        </button>
                    </h5>
                </div>

                <div id="collapseOne" className={`collapse ${isOpen ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                    {isLoading ? 
                        (
                            <div className="spinner-border text-primary" role="status">
                            </div>
                        ) 
                        : 
                        (
                            <table className={nurseordertable}>
                                <thead>
                                    <tr>
                                        <th scope="col">Items</th>
                                        <th scope="col">Group</th>
                                        <th scope="col">Inventory Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(lowItems).map(category => (
                                        lowItems[category].map((item, index) => (
                                            <tr key={ 'Test-' + index}>
                                                <td>{item.name}</td>
                                                <td>{category}</td>
                                                <td>{item.qty}</td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </table>
                        )
                    }
                    </div>
                </div>
            </div>
        </div>
        </>
    );
    
};

export default InventoryView;