import React from 'react';
import InventoryViewComponent from "components/nurse/dashboard/InventoryView";

const Dashboard = () => {
    return (
        <>
        <InventoryViewComponent/>
        <div className="container mt-5">
            <div className="row">
                <div className="col-12 text-center mb-4">
                    <h3>Patients Menu</h3>
                </div>
            </div>

            <div className="row"> 
                <div className="col-md-4 mb-4">
                    <a href="/nurse/patient-order" className="card-link">
                        <div className="card text-center">
                            <div className="card-body">
                                <h5 className="card-title"><b>Orders (ออเดอร์)</b></h5>
                                <p className="card-text">
                                    This is use to update the inventories for IV items based on what the customer's requirements
                                </p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="col-md-4 mb-4">
                    <a href="/nurse/patient-order-history" className="card-link">
                        <div className="card text-center">
                            <div className="card-body">
                                <h5 className="card-title"><b>Order History (ประวัติการสั่งซื้อ)</b></h5>
                                <p className="card-text">
                                    This is use to check the order history. It contain the order of each customer by date and the IV items.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-center mb-4">
                    <h3>Settings</h3>
                </div>
            </div>

            <div className="row"> 
                <div className="col-md-4 mb-4">
                    <a href="/nurse/settings-add-inventories" className="card-link">
                        <div className="card text-center">
                            <div className="card-body">
                                <h5 className="card-title"><b>+ Add Inventories (เพิ่มสต็อก)</b></h5>
                                <p className="card-text">
                                    Use to add the inventories for the patient orders item.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        </>
    );
};

export default Dashboard;
