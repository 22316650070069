import React, { useEffect, useState,useRef } from "react";
import { Modal,Form,Button} from "react-bootstrap";
import CustomerController from "controllers/CustomerController";

const GenerateMedsQrComp = () => {
    const fetchedRef = useRef(false); // Fetch report data when the component mounts
    const [medications, setMedications] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [qrCodeImage, setQRCodeImage] = useState(null);
    const [showQRModal, setShowQRModal] = useState(false);
    const [showCustModal, setShowCustModal] = useState(false);
    const [fullName, setFullName] = useState("");
    const [language, setLang] = useState("en");
    const [error, setError] = useState("");


    const fetchMedications = async () => {
        try {
            const medicationsData = await CustomerController.getAllMedications();
            setMedications(medicationsData);

        } catch (error) {
            // Handle error if needed
        }
    };

    useEffect(() => {
        if (!fetchedRef.current) {
            fetchMedications();
            fetchedRef.current = true; // Mark as fetched
        }
    }, []);

    const handleCheckboxChange = (medicationId) => {
        const updatedSelectedCheckboxes = selectedCheckboxes.includes(medicationId)
            ? selectedCheckboxes.filter(id => id !== medicationId)
            : [...selectedCheckboxes, medicationId];

        setSelectedCheckboxes(updatedSelectedCheckboxes);
    };

    const handleGenerateDocument = async () => {
        if (selectedCheckboxes.length <= 0){
            setError("There was no items selected. Please select atleast one.");
            return;
        }else{
            setError("");
            setShowCustModal(true);
        }
    };

    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };

    const handleDropdownChange = (event) => {
        setLang(event.target.value)
    };

    const handleCloseCustModal = async () =>{
        setShowCustModal(false);
        setFullName("");
        setLang("");
    }

    const handleCustModalSubmit = async () => {
        try {
            if (!fullName.trim()) {
                setError("Please enter your full name.");
                return;
            }
            if (!language) {
                setError("Please select a language.");
                return;
            }
            
            setError("");

			const param = {
				'cust_name': fullName,
				'lang': language,
				'medicationIds':selectedCheckboxes
			}
            const response = await CustomerController.generateMedFile(param);
            handleCloseCustModal();

            setQRCodeImage(response.qrObj.qr);
            setFullName(fullName)
            setShowQRModal(true);
        } catch (error) {
            console.error('Error generating document:', error);
        }
    }

    const handleCloseQRModal = () => {
        setShowQRModal(false);
        setQRCodeImage(null);
        setFullName('');
        window.location.reload();
    };

    const downloadQR = (qrURL) => {
        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = qrURL;
        downloadLink.download = `${fullName}_QR.png`; // You can set the filename here
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
       

    return (
        <>
            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Generate Medications QR</h6>
                        </div>  
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Name-English</th>
                                        <th scope="col">Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medications.map(medication => (
                                        <tr key={medication.id}>
                                            <th scope="row">
                                                <div className="custom-control custom-checkbox">
                                                    <input 
                                                        type="checkbox" 
                                                        className="custom-control-input" 
                                                        id={`customCheck_${medication.id}`} 
                                                        checked = {selectedCheckboxes.includes(medication.id)}
                                                        onChange={() => handleCheckboxChange(medication.id)}
                                                    />
                                                    <label className="custom-control-label" htmlFor={`customCheck_${medication.id}`}></label>
                                                </div>
                                            </th>
                                            <td>{medication.eng_name}</td>
                                            <td>
                                                <img 
                                                    src={medication.img_path} 
                                                    alt={medication.eng_name} 
                                                    style={{
                                                        width: '100px', 
                                                        height: '100px',
                                                        borderRadius: '10px'
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {error && <div className="text-danger">{error}</div>}
                            <button type="button" className="btn btn-primary float-right" onClick={handleGenerateDocument}>
                                <i className="bi bi-download"> </i>
                                Generate Document
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showQRModal} onHide={handleCloseQRModal}>
                <Modal.Header closeButton>
                    <Modal.Title>QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {qrCodeImage && <img src={qrCodeImage} alt="QR Code" />}
                    <button onClick={() => downloadQR(qrCodeImage)} className="btn btn-primary mt-3">Download QR as PNG</button>
                </Modal.Body>
            </Modal>

            <Modal show={showCustModal} onHide={handleCloseCustModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Customer Information</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <Form.Group controlId="formFullName" style={{ marginRight: '10px' }}>
                            <Form.Label>Full Name:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Full Name"
                                value={fullName}
                                onChange={handleFullNameChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formDropdown">
                            <Form.Label style={{ marginRight: '10px' }}>Language:</Form.Label>
                            <Form.Select value={language} onChange={handleDropdownChange}>
                                <option value="">Select a language</option>
                                <option value="en">English</option>
                                <option value="th">Thai</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    {error && <div className="text-danger">{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCustModalSubmit}>
                        Generate QR
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default GenerateMedsQrComp;