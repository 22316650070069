import React, { useState, useRef,useEffect } from 'react';
import AccountingController from "controllers/AccountingController";

const CommissionDisplay = () => {
    const fetchedRef = useRef(false);
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState("");
    const [timer, setTimer] = useState(60); // Timer state initialized to 60 seconds

    const [reports, setReports] = useState([]);

    // UPLOAD REPORT SECTION
    const handleFileChange = (event) => {
        setFile(event.target.files[0]); // Update the state with the selected file
    };

    const handleGenerateReport = async () => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        setIsLoading(true); // Start loading
        const formData = new FormData();
        formData.append('file', file); // Append the file to the FormData object

        try {
            const response = await AccountingController.commission(formData); // Call the controller function

            if(!response.status){
                setError(response.error);
                setIsLoading(false); // Start loading
                setTimer(60);
            }else{
                setError("");
                setIsLoading(false); // Start loading
                setTimer(60);
                window.location.reload();
            }

        } catch (error) {
            console.error('Error generating report:', error);
        }
    };

    // REPORT LIST SECTION
    const fetchReportData = async () => {
        setIsLoading(true); // Start loading
        setTimer(60);

        try {
            const response = await AccountingController.getCommission();
            if (Array.isArray(response)) {
                setReports(response);
            } else {
                console.error('Expected an array for reports, but got:', response);
                setReports([]); // Set reports to an empty array if response is not an array
            }
        } catch (error) {
            console.error('Error fetching report data:', error);
        }finally{
            setIsLoading(false); // Stop loading
        }
    }


    useEffect(() => {
        // Fetch Report Data - This block will only run once when the component mounts
        if (!fetchedRef.current) {
            fetchReportData();
            fetchedRef.current = true; // Mark as fetched
        }
    
        // Countdown Timer Logic - This block will run on every render when isLoading is true and timer > 0
        let interval = null;
        if (isLoading && timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1); // Decrease timer by 1 every second
            }, 1000);
        } else {
            clearInterval(interval); // Clear the interval if isLoading is false or timer reaches 0
        }
    
        // Cleanup function for the useEffect hook
        return () => clearInterval(interval); // Cleanup interval on component unmount or re-render
    }, [isLoading, timer]); 

    const downloadReport = (url,filename) => {
        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `${filename}.png`; // You can set the filename here
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="d-flex flex-column justify-content-center align-items-center"> {/* Added flex-column here */}
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        File Process Time Remaining: {timer} seconds
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="card shadow mb-4">

                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Generate Commission Report</h6>
                        </div>  
                        
                        <div className="card-body">
                            <div className="mb-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col">
                                            <label htmlFor="formFile" className="form-label"><b>Upload Dr Ease Income File: </b></label>
                                            <input className="form-control" type="file" id="formFile" onChange={handleFileChange} ref={fileInputRef}/>
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                   
                                    <br/>

                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col">
                                            <button type="button" className="btn btn-primary" onClick={handleGenerateReport}>
                                                <i className="bi bi-download"> </i>
                                                Generate Document
                                            </button>
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col">
                                            {error && <div className="text-danger">{error}</div>}
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Commission Report History</h6>
                        </div>  
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Report Date</th>
                                        <th scope="col">Report Name</th>
                                        <th scope="col">Generated On</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map(report => (
                                        <tr key={report.id}>
                                            <td>{report.report_date}</td>    
                                            <td>{report.file_name}</td>
                                            <td>{report.created}</td>
                                            <td>
                                                <button type="button" className="btn btn-primary float-center" style={{fontSize:'10px', padding:'2px 5px'}}  onClick={()=> downloadReport(report.link,report.file_name)}>
                                                    <i className="bi bi-download"> </i>
                                                    Download Report
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommissionDisplay;